import { Link } from "react-router-dom";
import CheckImg from "../../../assets/images/v3/check.svg";
import Shape2Img from "../../../assets/images/v3/shape2.png";
import Thumb1Img from "../../../assets/images/v3/thumb1.png";
function Benefits() {
	return (
		<div className="section section-padding2">
			<div className="container">
				<div className="row">
					<div className="col-lg-5 mt-lg-4">
						<div className="content-thumb ">
							<img src={Thumb1Img} alt="Thumb1Img" />
							<div className="thumb-shape1">
								<img src={Shape2Img} alt="Shape2Img" />
							</div>
						</div>
					</div>
					<div className="col-lg-6 offset-lg-1">
						<div className="default-content familjen-grotesk">
							<h2 style={{ fontSize: "70px" }}>DishDash Benefits</h2>
							<div className="list-icon">
								<ul>
									<li>
										<img src={CheckImg} alt="CheckImg" />
										Ultimate Convenience
										<p style={{ fontWeight: "normal" }}>Place orders via the DishDash app or any mobile browser for seamless on-the-go pickup.</p>
									</li>
									<li>
										<img src={CheckImg} alt="CheckImg" />
										Save Time
										<p style={{ fontWeight: "normal" }}>
											Skip the lines and avoid parking hassles. Quick and efficient curbside pickup without leaving your car.
										</p>
									</li>
									<li>
										<img src={CheckImg} alt="CheckImg" />
										Stay Safe
										<p style={{ fontWeight: "normal" }}>
											With hands-free after order placement, enjoy contactless pickup for a safer shopping experience.
										</p>
									</li>
									<li>
										<img src={CheckImg} alt="CheckImg" />
										Freshness Guaranteed
										<p style={{ fontWeight: "normal" }}>
											Always receive fresh products, hot food, and beverages.
										</p>
									</li>
									<li>
										<img src={CheckImg} alt="CheckImg" />
										Secure Online Payment
										<p style={{ fontWeight: "normal" }}>
											Make secure payments online, ensuring your transaction is safe and easy.
										</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="btn-wrap d-md-flex gap-5">
							<Link className="default-btn pill yellow-btn" to="https://dl.geome.io/u/QRyD">
								<span className="label-up">Get The App</span>
								<span className="label-up">Get The App</span>
							</Link>
							<Link className="default-btn pill yellow-btn" to="https://web.dishdash.io/">
								<span className="label-up">Use In Browser</span>
								<span className="label-up">Use In Browser</span>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Benefits;
