import Icon1Img from "../../../assets/images/v3/icon1.svg";
import Icon2Img from "../../../assets/images/v3/icon2.svg";
import Icon3Img from "../../../assets/images/v3/icon3.svg";
import Icon4Img from "../../../assets/images/v3/icon4.svg";
import StarShapeHalfImg from "../../../assets/images/v3/star-shape-half.png";
import ServiceCard from "./ServiceCard";
const servicesData = [
	{
		id: 1,
		title: "Order Online",
		description:
			"Choose your items from a variety of participating businesses.",
		img: Icon1Img,
	},
	{
		id: 2,
		title: "Pay Securely",
		description:
			"Make your payment online for a quick and safe transaction.",
		img: Icon2Img,
	},
	{
		id: 3,
		title: "Curbside Pickup",
		description:
			"Drive to the designated curbside pickup spot, and your order will be brought to you.",
		img: Icon3Img,
	},
	{
		id: 4,
		title: "Other Pickup Options",
		description:
			"Pick up your order in-store or at the table, saving time and staying in control.",
		img: Icon4Img,
	},
];
function Work() {
	return (
		<div className="section section-padding dark-bg overflow-hidden position-relative">
			<div className="container">
				<div className="section-title center familjen-grotesk light">
					<h2>How It Works</h2>
				</div>
				<div className="iconbox-column">
					{servicesData.map((service) => (
						<ServiceCard key={service.id} service={service} />
					))}
				</div>
			</div>

			<div className="iconbox-shape">
				<img src={StarShapeHalfImg} alt="StarShapeHalfImg" />
			</div>
		</div>
	);
}

export default Work;
