import NavItem from "./NavItem";
import { routes } from "./routes";
function Navbar({
	toggleMenu,
	handleMenu,
	handleGoBack,
}) {

	return (
		<nav className={`menu-block ${toggleMenu ? "active" : ""}`}>
			<div className={`mobile-menu-head`}>
				<div className="go-back" onClick={handleGoBack}>
					<i className="fa fa-angle-left"></i>
				</div>
				<div className="current-menu-title"></div>
				<div className="mobile-menu-close" onClick={handleMenu}>
					&times;
				</div>
			</div>
			<ul className="site-menu-main">
				{routes.map((route) => (
					<NavItem
						key={route.title}
						route={route}
					/>
				))}
			</ul>
		</nav>
	);
}

export default Navbar;
