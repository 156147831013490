import Benefits from "../../components/consumers/benefits";
import Hero from "../../components/consumers/hero";
import Work from "../../components/consumers/work";

function ConsumersPage() {
	return (
		<div className="all-section bg-light3">
			<Hero />
			<Work />
			<Benefits />
		</div>
	);
}

export default ConsumersPage
