import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getPost } from "../../../components/blog/lib/cosmic";
import { sanitize } from "isomorphic-dompurify";
import Tag from "../../../components/blog/Tag";
import AuthorAvatar from "../../../components/blog/author/AuthorAvatar";
import AuthorAttribution from "../../../components/blog/author/AuthorAttribution";
import Preloader from "../../../components/common/Preloader";
import ArrowLeft from "../../../components/blog/icons/ArrowLeft";
import { Helmet } from "react-helmet-async";

export default function SinglePost() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchPosts() {
      setLoading(true);
      try {
        const postData = await getPost(slug);
        setPost(postData);
      } catch (err) {
        setError("Failed to load posts.");
      } finally {
        setLoading(false);
      }
    }
    fetchPosts();
  }, [slug]);

  if (error) return <p className="text-red-500">{error}</p>;
  if (loading) return <Preloader />;
  if (!post) return <div className="text-center">Post Not Found</div>;

  // Construct the canonical URL using a fixed protocol/domain and the unique post slug
  const canonicalUrl = `https://dishdash.io/posts/${slug}`;

  return (
      <div>
        <Helmet>
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        {post.metadata.hero?.imgix_url && (
            <img
                className="single-post-image"
                src={`${post.metadata.hero.imgix_url}?w=1400&auto=format,compression`}
                alt={post.title}
            />
        )}
        <main className="single-post-main">
          <div className="single-post-main-container">
            <div className="single-post-arrow-container">
              <Link to="/posts" className="single-post-arrow-link">
                <ArrowLeft className="single-post-arrow" />
              </Link>
            </div>
            <div className="single-post-content-container">
              <h2 className="single-post-title">{post.title}</h2>
              <div className="single-post-author-tag-container">
                <div className="single-post-author">
                  <AuthorAvatar post={post} />
                  <AuthorAttribution post={post} />
                </div>
                <div className="single-post-tag">
                  {post.metadata.categories &&
                      post.metadata.categories.map((category) => (
                          <Tag key={category.title}>{category.title}</Tag>
                      ))}
                </div>
              </div>
              <hr className="single-post-hr" />
              <div
                  className="single-post-text"
                  dangerouslySetInnerHTML={{ __html: sanitize(post.metadata.content) ?? "" }}
              ></div>
            </div>
          </div>
        </main>
      </div>
  );
}
