import Shape1Img from "../../../assets/images/v1/shape.png";
import Star2Img from "../../../assets/images/v1/star2-orange.png";
import youtubeIcon from "../../../assets/images/icon/youtube-lightt.png";

function FooterContent() {
	return (
		<div className="default-content light position-relative">
			<h2>
				<span className="title-animation">
					Get Started
					<span className="title-icon">
						<img src={Star2Img} alt="Star2Img" />
					</span>
				</span>
			</h2>
			<p>
				Have questions or need more information about DishDash?
				Fill out the form below, and our team will get back to you promptly.
				We’re here to help you boost your sales, streamline your operations,
				and enhance your customer experience.
			</p>
			<div className="info-wrap">
				<div className="info">
					<ul>
						<li>Send us an email:</li>
						<li>
							<a href="mailto:sales@dishdash.io">sales@dishdash.io</a>
						</li>
					</ul>
				</div>
			</div>
			<div className="social-icon social-large">
				<ul>
					<li>
						<a href="https://www.facebook.com/dishdashtechnology/" aria-label="Facebook profile" target="_blank" rel="noopener noreferrer">
							<i className="icon-facebook"></i>
						</a>
					</li>
					<li>
						<a href="https://www.instagram.com/dishdashtechnology/" aria-label="Instagram profile" target="_blank" rel="noopener noreferrer">
							<i className="icon-instagram"></i>
						</a>
					</li>
					<li>
						<a href="https://www.linkedin.com/company/geome/" aria-label="Linkedin profile" target="_blank" rel="noopener noreferrer">
							<i className="icon-linkedin"></i>
						</a>
					</li>
					<li>
						<a href="https://www.youtube.com/channel/UChbWAEoq7SohcVXz1eO5GkA" aria-label="Youtube profile" target="_blank" rel="noopener noreferrer">
							<i>
								<img src={youtubeIcon} alt="" width={20} height={20} style={{ marginBottom: "4px", marginLeft: "2px" }} />
							</i>
						</a>
					</li>
				</ul>
			</div>
			<div className="hero-shape footer-shape">
				<img src={Shape1Img} alt="Shape1Img" />
			</div>
		</div>
	);
}

export default FooterContent;
