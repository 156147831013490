import Hero from "../../components/business/hero";
import Services from "../../components/business/services";

import Benefits from "../../components/business/benefits";
import Pricing from "../../components/business/pricing/Pricing";
import GetStart from "../../components/business/getStart";

function BusinessPage() {
	return (
		<>
			<Hero />
			<GetStart />
			<Services />
			<Benefits />
			<Pricing />
		</>
	);
}

export default BusinessPage;
