import { Link } from "react-router-dom";
import './index.css'
function HeaderButton() {
	return (
		<section class="wrapper">
			<div class="top">DishDash Blog</div>
			<div class="bottom" aria-hidden="true">DishDash Blog</div>
		</section>
	);
}

export default HeaderButton;
