import { Outlet } from "react-router-dom";
import Footer from "../business/footer";
import Header from "../business/header";
import { Helmet } from "react-helmet-async";
function BusinessPageLayout() {
	const jsonLdOrganization = {
		"@context": "https://schema.org",
		"@type": "Organization",
		"name": "Dishdash",
		"url": "https://dishdash.io",
		"logo": "https://dishdash.io/assets/dd3-B891fmrY.png",
		"sameAs": [
			"https://facebook.com/dishdashtechnology",
			"https://www.instagram.com/dishdashtechnology",
			"https://www.tiktok.com/@dishdash77",
			"https://www.youtube.com/channel/UChbWAEoq7SohcVXz1eO5GkA",
			"https://www.linkedin.com/company/geome"
		],
		"contactPoint": [
			{
				"@type": "ContactPoint",
				"contactType": "Customer Support",
				"email": "support@dishdash.io",
				"areaServed": "Global",
				"availableLanguage": ["English", "Arabic"]
			},
			{
				"@type": "ContactPoint",
				"contactType": "Sales",
				"email": "sales@dishdash.io",
				"areaServed": "Global",
				"availableLanguage": ["English", "Arabic"]
			}
		]
	};

	const jsonLdServices = {
		"@context": "https://schema.org",
		"@graph": [
			{
				"@type": "Service",
				"@id": "https://dishdash.io#curbsidePickup",
				"name": "Curbside Pickup Technology",
				"description": "Enable seamless curbside pickup for customers without the need for parking or waiting.",
				"provider": {
					"@type": "Organization",
					"name": "Dishdash",
					"url": "https://dishdash.io"
				},
				"areaServed": "Global",
				"offers": {
					"@type": "Offer",
					"priceCurrency": "USD",
					"price": "Contact for discount coupon",
					"url": "https://dishdash.io/#footer"
				}
			},
			{
				"@type": "Service",
				"@id": "https://dishdash.io#bopis",
				"name": "BOPIS (Buy Online, Pick Up In-Store)",
				"description": "Streamline the in-store pickup process for customers with our efficient BOPIS solution.",
				"provider": {
					"@type": "Organization",
					"name": "Dishdash",
					"url": "https://dishdash.io"
				},
				"areaServed": "Global",
				"offers": {
					"@type": "Offer",
					"priceCurrency": "USD",
					"price": "Contact for discount coupon",
					"url": "https://dishdash.io#footer"
				}
			},
			{
				"@type": "Service",
				"@id": "https://dishdash.io#tableOrdering",
				"name": "Table Ordering",
				"description": "Allow customers to order directly from their table using our contactless ordering solution.",
				"provider": {
					"@type": "Organization",
					"name": "Dishdash",
					"url": "https://dishdash.io"
				},
				"areaServed": "Global",
				"offers": {
					"@type": "Offer",
					"priceCurrency": "USD",
					"price": "Contact for discount coupon",
					"url": "https://dishdash.io#footer"
				}
			}
		]
	};
	return (
		<>
			<Helmet>
				<title>Order & Pickup Services for Restaurants Cafés .. | DishDash</title>
				<meta name="description" content="Boost sales and streamline your operations with DishDash. Perfect for restaurants, cafés, pharmacies, and more. Try Curbside Pickup, In-Store Pickup, Table Ordering, and Counter Pickup." />
  				<meta name="robots" content="index, follow" />
				<link rel="canonical" href="https://dishdash.io" />
				<meta property="og:title" content="Order & Pickup Services for Restaurants Cafés .. | DishDash" />
				<meta property="og:description" content="Boost sales and streamline your operations with DishDash. Perfect for restaurants, cafés, pharmacies, and more. Try Curbside Pickup, In-Store Pickup, Table Ordering, and Counter Pickup." />
				<meta property="og:image" content="https://dishdash.io/assets/dd3-B891fmrY.png" />
  				<meta property="og:url" content="https://dishdash.io" />
				{/* JSON-LD for Organization */}
				<script type="application/ld+json">
					{JSON.stringify(jsonLdOrganization)}
				</script>
				{/* JSON-LD for Services */}
				<script type="application/ld+json">
					{JSON.stringify(jsonLdServices)}
				</script>
			</Helmet>
			<Header />
			<Outlet />
			<Footer />
		</>
	);
}

export default BusinessPageLayout
