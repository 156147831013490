import React from 'react';
import { Outlet } from 'react-router-dom';

import FooterBottom from '../business/footer/FooterBottom';
import Header from '../blog/header';

import '../../assets/css/blog.css';

import { Helmet } from 'react-helmet-async';

const BlogsPageLayout = () => {
  return (
    <div className='layout'>
      <Helmet>
        <title>Value Order & Curbside Pickup App for Businesses | DishDash</title>
        <meta name="description" content="Best value curbside pickup app. Boost sales and streamline your operations with DishDash. Perfect for restaurants, cafés, pharmacies, and more. Try Curbside Pickup, In-Store Pickup, Table Ordering, and Counter Pickup." />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Best Value Order & Curbside Pickup App for Businesses | DishDash" />
        <meta property="og:description" content="Best value curbside pickup app. Boost sales and streamline your operations with DishDash. Perfect for restaurants, cafés, pharmacies, and more. Try Curbside Pickup, In-Store Pickup, Table Ordering, and Counter Pickup." />
        <meta property="og:image" content="https://dishdash.io/assets/dd3-B891fmrY.png" />
        <meta property="og:url" content="https://dishdash.io/posts" />
      </Helmet>
      <Header />
      <main className='blogs-main'>
        <Outlet />
      </main>
      <div className='blogs-footer'>
        <FooterBottom />
      </div>
    </div>
  )
}

export default BlogsPageLayout