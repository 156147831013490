import helpers from "../../../page/home/blogs/helpers";

export default function AuthorAttribution({ post }) {
  return (
    <div className="author-attribution-container">
      <span>by</span>
      <span className="author-attribution">{post.metadata.author?.title}</span>
      <span>
        on {helpers.stringToFriendlyDate(post.metadata.published_date)}
      </span>
    </div>
  );
}
