function Accordion() {
	return (
		<div className="accordion accordion-wrap d-block d-lg-flex justify-content-center gap-4" id="accordion">
			<div className=" d-flex flex-column gap-3 mb-3">
				<div className="accordion-item">
					<h3 className="accordion-header">
						<button
							className="accordion-button"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseOne"
						>
							01/ Sign Up Using The DishDash Admin
						</button>
					</h3>
					<div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordion">
						<div className="accordion-body">
							Complete your business profile by adding details like your business name and contact information.
						</div>
						<a className="btn" style={{ textDecoration: 'underline' }} href="https://admin.dishdash.io/">Go to DishDash Admin</a>
					</div>
				</div>
				<div className="accordion-item">
					<h3 className="accordion-header" id="headingOne">
						<button
							className="accordion-button"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseTwo"
						>
							02/ Upload Your Menu
						</button>
					</h3>
					<div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordion">
						<div className="accordion-body">
							Easily upload your menu to our online ordering platform. Include item descriptions, prices, photos, and other details to provide an appealing menu for your customers.
						</div>
					</div>
				</div>
				<div className="accordion-item">
					<h3 className="accordion-header">
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseThree"
						>
							03/ Activate Online Payment System
						</button>
					</h3>
					<div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordion">
						<div className="accordion-body">
							Connect your nominated bank account to our integrated online payment system. This ensures that payments from your customers go directly to you.
						</div>
					</div>
				</div>
				<div className="accordion-item">
					<h3 className="accordion-header">
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseFour"
						>
							04/ Set Up Free Trial
						</button>
					</h3>
					<div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordion">
						<div className="accordion-body">
							Set up a subscription to start with our no-risk, cancel-anytime free trial. Experience all the benefits of DishDash without making any payments.					</div>
					</div>
				</div>
			</div>
			<div className=" d-flex flex-column gap-3">
				<div className="accordion-item">
					<h3 className="accordion-header">
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseFive"
						>
							05/ Create and Set Up a Branch
						</button>
					</h3>
					<div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordion">
						<div className="accordion-body">
							Set up your branch profile, including address, hours of operation, and Pickup Options to fit your business needs and customer preferences. Make your branch visible to customers.					</div>
					</div>
				</div>
				<div className="accordion-item">
					<h3 className="accordion-header">
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseSix"
						>
							06/ Promote Your New Services
						</button>
					</h3>
					<div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordion">
						<div className="accordion-body">
							Use our marketing digital assets and the link to your branch to reach out to your existing customers and attract new ones. Highlight the convenience and efficiency of your new ordering and pickup system.					</div>
					</div>
				</div>
				<div className="accordion-item">
					<h3 className="accordion-header">
						<button
							className="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#collapseSeven"
						>
							07/ Receive and Manage Orders
						</button>
					</h3>
					<div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordion">
						<div className="accordion-body">
							Use the DishDash Business app to receive and manage orders.
						</div>
						<a className="btn" style={{ textDecoration: 'underline' }} href="https://dl.geome.io/dt/2uTk">Download the DishDash Business app</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Accordion;
