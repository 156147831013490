// import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Layout from "../components/layout/index.jsx";
import ErrorPage from "../error-page";
import BusinessPage from "../page/home/BusinessPage.jsx";
import ConsumersPage from "../page/home/ConsumersPage.jsx";
import BusinessPageLayout from "../components/layout/BusinessPageLayout.jsx";
import ConsumersPageLayout from "../components/layout/ConsumersPageLayout.jsx";
import BlogsPageLayout from "../components/layout/BlogsPageLayout.jsx";
import CardList from "../page/home/blogs/CardList.jsx";
import SinglePost from "../page/home/blogs/SinglePost.jsx";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <Layout />,
		children: [
			{
				path: "/",
				element: <BusinessPageLayout />,
				children: [
					{
						path: "/",
						element: <BusinessPage />,
					},
					{
						path: "*",
						element: <ErrorPage />,
					},
				],
			},
			{
				path: "/",
				element: <ConsumersPageLayout />,
				children: [
					{
						path: "/consumers-page",
						element: <ConsumersPage />,
					},
				],
			},
			{
				path: "/posts",
				element: <BlogsPageLayout />,
				children: [
					{
						path: "/posts",
						element: <CardList />,
					},
					{
						path: "/posts/:slug",
						element: <SinglePost />,
					},
				],
			},
		],
	},
]);
