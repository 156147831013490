import { useEffect, useState } from "react";
import { getAllPosts } from "../../../components/blog/lib/cosmic";
import PostCard from "../../../components/blog/PostCard";
import Preloader from "../../../components/common/Preloader";

export default function CardList() {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchPosts() {
      setLoading(true);
      try {
        const posts = await getAllPosts();
        setPosts(posts);
      } catch (err) {
        setError("Failed to load posts.");
      } finally {
        setLoading(false);
      }
    }

    fetchPosts();
  }, []);

  if (loading) return <Preloader />;

  return (
    <div className="card-list-container">
      {error && <p className="text-red">{error}</p>}
      {posts.length === 0 && !error && (
        <p>You must add at least one Post to your Bucket</p>
      )}
      {posts.map((post) => (
        <div key={post.id}>
          <PostCard post={post} />
        </div>
      ))}
    </div>
  );
}
